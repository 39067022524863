<template>
    <app-wrapper>
        <template v-slot:child>
            <div class="flex flex-wrap justify-between items-center w-90 center pv3">
                <div class="w-100">
                    <div class="pad16">
                        <p class="pb2 f3 b">
                            Preview page for Bulk upload
                            <strong
                                ><font-awesome-icon
                                    icon="fa-solid fa-question-circle fa-2xs"
                                    class="text-muted clickModalIcon"
                                    @click="modalBulkSEP = true"
                                    style="cursor: pointer; padding-bottom: 1px"
                            /></strong>
                        </p>
                        <div class="dashboard-inquiry-modal" v-if="modalBulkSEP">
                            You can upload your Sales from Excel to make your Account up-to-date. Click
                            <a href="#" target="_blank" style="color: blue">here</a> to learn more.
                        </div>
                    </div>

                    <div class="flex flex-wrap justify-between">
                        <div class="flex flex-column gap16 w-30-l w-100 box-border pa4 mt4" style="border-radius: 8px">
                            <p><img :src="require('@/assets/images/SalesIcon.svg')" /></p>
                            <router-link :to="{name: 'ViewBulkUpdateSEP'}"><p class="font20 b">Sales</p></router-link>
                            <p class="font24 blueColor b">N50,000.75</p>
                            <div class="flex gap8">
                                <p class="flex items-center">
                                    <span
                                        ><img :src="require('@/assets/images/salesGreen.svg')" height="20px" width="20px"
                                    /></span>
                                    <span class="b pr2">40 </span>
                                    <span class="font12"> Transactions successfully</span>
                                </p>
                                <p class="flex items-center">
                                    <span><img :src="require('@/assets/images/salesRed.svg')" height="12px" width="12px" /></span
                                    ><span class="b pr2"> 4 </span> <span class="font12">Failed Transaction</span>
                                </p>
                            </div>
                            <p class="font16 b">Banks used</p>
                            <p class="f500">GTB - N10,000.00</p>
                            <p class="f500">First Bank - N25,000.00</p>
                            <p class="f500">Cash - N15,000/75</p>
                        </div>
                        <div class="flex flex-column gap16 w-30-l w-100 box-border pa4 mt4" style="border-radius: 8px">
                            <p><img :src="require('@/assets/images/bulkExpense.svg')" /></p>
                            <router-link :to="{name: 'ViewBulkUpdateSEP'}"> <p class="font20 b">Expenses</p></router-link>
                            <p class="font24 blueColor b">N50,000.75</p>
                            <div class="flex gap8">
                                <p class="flex items-center">
                                    <span
                                        ><img :src="require('@/assets/images/salesGreen.svg')" height="20px" width="20px"
                                    /></span>
                                    <span class="b pr2">40 </span>
                                    <span class="font12"> Transactions successfully</span>
                                </p>
                                <p class="flex items-center">
                                    <span><img :src="require('@/assets/images/salesRed.svg')" height="12px" width="12px" /></span
                                    ><span class="b pr2"> 4 </span> <span class="font12">Failed Transaction</span>
                                </p>
                            </div>
                            <p class="font16 b">Banks used</p>
                            <p class="f500">GTB - N10,000.00</p>
                            <p class="f500">First Bank - N25,000.00</p>
                            <p class="f500">Cash - N15,000/75</p>
                        </div>
                        <div class="flex flex-column gap16 w-30-l w-100 box-border pa4 mt4" style="border-radius: 8px">
                            <p><img :src="require('@/assets/images/bulkPurchase.svg')" /></p>
                            <router-link :to="{name: 'ViewBulkUpdateSEP'}"><p class="font20 b">Purchases</p></router-link>
                            <p class="font24 blueColor b">N50,000.75</p>
                            <div class="flex gap8">
                                <p class="flex items-center">
                                    <span
                                        ><img :src="require('@/assets/images/salesGreen.svg')" height="20px" width="20px"
                                    /></span>
                                    <span class="b pr2">40 </span>
                                    <span class="font12"> Transactions successfully</span>
                                </p>
                                <p class="flex items-center">
                                    <span><img :src="require('@/assets/images/salesRed.svg')" height="20px" width="20px" /></span
                                    ><span class="b pr2"> 4 </span> <span class="font12">Failed Transaction</span>
                                </p>
                            </div>
                            <p class="font16 b">Banks used</p>
                            <p class="f500">GTB - N10,000.00</p>
                            <p class="f500">First Bank - N25,000.00</p>
                            <p class="f500">Cash - N15,000/75</p>
                        </div>
                    </div>

                    <div class="box-border pa3 mt3" style="border-radius: 8px">
                        <h3>Here is a Summary of your update</h3>
                        <div class="flex gap8">
                            <p class="flex items-center">
                                <span><img :src="require('@/assets/images/salesGreen.svg')" height="20px" width="20px" /></span>
                                <span class="b pr2">40 </span>
                                <span class="font12"> Transactions successfully</span>
                            </p>
                            <p class="flex items-center">
                                <span><img :src="require('@/assets/images/salesRed.svg')" height="12px" width="12px" /></span
                                ><span class="b pr2"> 4 </span> <span class="font12">Failed Transaction</span>
                            </p>
                        </div>
                        <div class="mt4 flex" style="justify-content: end">
                            <div class="flex gap8">
                                <button class="btn2">View All Transactions</button>
                                <button class="btn-primary">Complete Import</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template></app-wrapper
    >
</template>
<script>
import { ref } from 'vue'
import AppWrapper from '@/layout/AppWrapper'
export default {
    name: 'BulkUpdateSEP',
    components: { AppWrapper },
    setup() {
        const modalBulkSEP = ref(false)

        return {
            modalBulkSEP,
        }
    },
}
</script>
